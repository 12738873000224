import { __decorate, __extends, __metadata } from "tslib";
/**
 *  Created by pw on 2020/12/1 9:07 下午.
 */
import { attribute, Model } from 'mobx-cobweb';
import { computed } from 'mobx';
import { MoneyIF, HomeVisibilityIF } from '@ekuaibao/ekuaibao_types';
import { Staff, Specification, Feetype, PayeeInfo, InvoiceForm } from '@ekuaibao/collection-definition';
import moment from 'moment';
import WithnoteGroup from './WithnoteGroup';
import { WithnoteDetailType } from '../types';
var WithnoteDetailForm = /** @class */ (function (_super) {
    __extends(WithnoteDetailForm, _super);
    function WithnoteDetailForm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(WithnoteDetailForm.prototype, "feeDateStr", {
        get: function () {
            var format = i18n.get('YYYY年MM月DD日');
            if (!this.feeDate)
                return i18n.get('无消费日期');
            return moment(this.feeDate).format(format);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WithnoteDetailForm.prototype, "invoiceFormType", {
        get: function () {
            if (this.invoiceForm && this.invoiceForm.type !== 'noWrite') {
                if (this.invoiceForm.type === 'exist') {
                    return this.invoiceForm.invoiceConfirm
                        ? this.invoiceForm.invoiceConfirm === 'false'
                            ? 'existConfirm'
                            : this.invoiceForm.type
                        : this.invoiceForm.type;
                }
                return this.invoiceForm.type;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WithnoteDetailForm.prototype, "stateType", {
        get: function () {
            var map = {
                FLOW: { archived: WithnoteDetailType.Archived, paid: WithnoteDetailType.Archived },
                NOTES: { draft: WithnoteDetailType.Draft, paid: WithnoteDetailType.Draft }
            };
            if (this.linkType === 'FLOW') {
                return map[this.linkType][this.state] || WithnoteDetailType.Pendding;
            }
            if (this.linkType === 'NOTES') {
                return map[this.linkType][this.state] || '';
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WithnoteDetailForm.prototype, "stateString", {
        get: function () {
            var _a;
            var map = (_a = {},
                _a[WithnoteDetailType.Draft] = '待报销',
                _a[WithnoteDetailType.Pendding] = '报销中',
                _a[WithnoteDetailType.Archived] = '已报销',
                _a);
            return i18n.get(map[this.stateType]) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WithnoteDetailForm.prototype, "disabled", {
        get: function () {
            return this.stateType !== WithnoteDetailType.Draft;
        },
        enumerable: false,
        configurable: true
    });
    var _a, _b, _c, _d, _e, _f;
    WithnoteDetailForm.type = 'withnodes.detail.form';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], WithnoteDetailForm.prototype, "detailId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_a = typeof MoneyIF !== "undefined" && MoneyIF) === "function" ? _a : Object)
    ], WithnoteDetailForm.prototype, "amount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetailForm.prototype, "code", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetailForm.prototype, "consumptionReasons", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetailForm.prototype, "detailType", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteDetailForm.prototype, "feeDate", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], WithnoteDetailForm.prototype, "feeDatePeriod", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetailForm.prototype, "linkType", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetailForm.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_b = typeof Staff !== "undefined" && Staff) === "function" ? _b : Object)
    ], WithnoteDetailForm.prototype, "ownerId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetailForm.prototype, "stage", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetailForm.prototype, "state", void 0);
    __decorate([
        attribute({ toOne: Specification }),
        __metadata("design:type", typeof (_c = typeof Specification !== "undefined" && Specification) === "function" ? _c : Object)
    ], WithnoteDetailForm.prototype, "specificationId", void 0);
    __decorate([
        attribute({ toOne: Feetype }),
        __metadata("design:type", typeof (_d = typeof Feetype !== "undefined" && Feetype) === "function" ? _d : Object)
    ], WithnoteDetailForm.prototype, "feeTypeId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_e = typeof PayeeInfo !== "undefined" && PayeeInfo) === "function" ? _e : Object)
    ], WithnoteDetailForm.prototype, "feeDetailPayeeId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_f = typeof InvoiceForm !== "undefined" && InvoiceForm) === "function" ? _f : Object)
    ], WithnoteDetailForm.prototype, "invoiceForm", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], WithnoteDetailForm.prototype, "attachments", void 0);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], WithnoteDetailForm.prototype, "feeDateStr", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], WithnoteDetailForm.prototype, "invoiceFormType", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], WithnoteDetailForm.prototype, "stateType", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], WithnoteDetailForm.prototype, "stateString", null);
    __decorate([
        computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], WithnoteDetailForm.prototype, "disabled", null);
    return WithnoteDetailForm;
}(Model));
export { WithnoteDetailForm };
var WithnoteDetail = /** @class */ (function (_super) {
    __extends(WithnoteDetail, _super);
    function WithnoteDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _g, _h, _j, _k, _l;
    WithnoteDetail.type = 'withnodes.detail';
    WithnoteDetail.endpoint = '/api/quickexpense/v1';
    WithnoteDetail.userCategory = '/api/v1/quickExpenseConfig';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "description", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], WithnoteDetail.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "code", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteDetail.prototype, "controlCalcVersion", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteDetail.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "entityId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteDetail.prototype, "index", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_g = typeof MoneyIF !== "undefined" && MoneyIF) === "function" ? _g : Object)
    ], WithnoteDetail.prototype, "ledgerAmount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "masterId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_h = typeof Staff !== "undefined" && Staff) === "function" ? _h : Object)
    ], WithnoteDetail.prototype, "operatorId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_j = typeof Staff !== "undefined" && Staff) === "function" ? _j : Object)
    ], WithnoteDetail.prototype, "ownerId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], WithnoteDetail.prototype, "sharedStaffs", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "platformId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "source", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "sourceId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteDetail.prototype, "totalCount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteDetail.prototype, "updateTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteDetail.prototype, "useCount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteDetail.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], WithnoteDetail.prototype, "visible", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "reviewStatus", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteDetail.prototype, "sourceMessage", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_k = typeof HomeVisibilityIF !== "undefined" && HomeVisibilityIF) === "function" ? _k : Object)
    ], WithnoteDetail.prototype, "visibility", void 0);
    __decorate([
        attribute({ toOne: WithnoteDetailForm }),
        __metadata("design:type", WithnoteDetailForm)
    ], WithnoteDetail.prototype, "form", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_l = typeof WithnoteGroup !== "undefined" && WithnoteGroup) === "function" ? _l : Object)
    ], WithnoteDetail.prototype, "notesGroup", void 0);
    return WithnoteDetail;
}(Model));
export default WithnoteDetail;
