import { __decorate, __extends, __metadata } from "tslib";
/**
 *  Created by pw on 2020/11/30 12:14 下午.
 */
import { attribute, Model } from 'mobx-cobweb';
import { MoneyIF } from '@ekuaibao/ekuaibao_types';
import WithnoteGroup from './WithnoteGroup';
import WithnoteDetail from './WithnoteDetail';
import { computed } from 'mobx';
var WithnoteRelationDetail = /** @class */ (function (_super) {
    __extends(WithnoteRelationDetail, _super);
    function WithnoteRelationDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(WithnoteRelationDetail.prototype, "disabled", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.relationDetail) === null || _a === void 0 ? void 0 : _a.form) === null || _b === void 0 ? void 0 : _b.disabled;
        },
        enumerable: false,
        configurable: true
    });
    var _a, _b, _c;
    WithnoteRelationDetail.type = 'withnodes.group.relation.detail';
    WithnoteRelationDetail.endpoint = '/api/withNotesGroupRelation/v2';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], WithnoteRelationDetail.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteRelationDetail.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], WithnoteRelationDetail.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteRelationDetail.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteRelationDetail.prototype, "updateTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteRelationDetail.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_a = typeof WithnoteGroup !== "undefined" && WithnoteGroup) === "function" ? _a : Object)
    ], WithnoteRelationDetail.prototype, "groupId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteRelationDetail.prototype, "detailCode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteRelationDetail.prototype, "detailId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_b = typeof MoneyIF !== "undefined" && MoneyIF) === "function" ? _b : Object)
    ], WithnoteRelationDetail.prototype, "amount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], WithnoteRelationDetail.prototype, "detailState", void 0);
    __decorate([
        attribute({
            toOne: WithnoteDetail,
            parse: function (value, data) {
                return data;
            }
        }),
        __metadata("design:type", typeof (_c = typeof WithnoteDetail !== "undefined" && WithnoteDetail) === "function" ? _c : Object)
    ], WithnoteRelationDetail.prototype, "relationDetail", void 0);
    __decorate([
        computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], WithnoteRelationDetail.prototype, "disabled", null);
    return WithnoteRelationDetail;
}(Model));
export default WithnoteRelationDetail;
