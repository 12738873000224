import { __assign } from "tslib";
/**
 *  Created by gym on 2020/3/12 下午2:46.
 */
import { Reducer } from '@ekuaibao/store';
import key from './key';
import { catchError } from '@ekuaibao/lib/lib/lib-util';
import { showMessage } from '@ekuaibao/show-util';
var reducer = new Reducer(key.ID, {
    isNeedPayInfo: false,
    currentWithNotesId: '',
    feeTypeImportRuleList: []
});
reducer.handle(key.GET_WITHNOTES_LIST)(catchError(function (state, action) {
    return __assign({}, state);
}));
reducer.handle(key.SAVE_WITHNOTES)(catchError(function (state, action) {
    showMessage.success(i18n.get('保存成功'));
    return __assign({}, state);
}));
reducer.handle(key.MODIFY_WITHNOTES)(catchError(function (state, action) {
    showMessage.success(i18n.get('修改成功'));
    return __assign({}, state);
}));
reducer.handle(key.DELETE_WITHNOTES)(catchError(function (state, action) {
    showMessage.success(i18n.get('删除成功'));
    return __assign({}, state);
}));
reducer.handle(key.DELETE_BATCH_WITHNOTES)(catchError(function (state, action) {
    showMessage.success(i18n.get('删除成功'));
    return __assign({}, state);
}));
reducer.handle(key.GET_DETAILS_VALUES_BYID)(catchError(function (state, action) {
    return __assign({}, state);
}));
reducer.handle(key.SET_CAN_ADD_PAY_INFO)(catchError(function (state, action) {
    var data = action.payload;
    return __assign(__assign({}, state), { isNeedPayInfo: data });
}));
reducer.handle(key.SHARE_RECORD_EXPENSE)(catchError(function (state, action) {
    return __assign({}, state);
}));
reducer.handle(key.GET_SHARED_STAFFS_LIST)(catchError(function (state, action) {
    return __assign({}, state);
}));
reducer.handle(key.SET_RECORD_EXPENSE_PAY_CONFIG)(catchError(function (state, action) {
    var value = action.payload.value;
    return __assign(__assign({}, state), { isNeedPayInfo: value });
}));
reducer.handle(key.CHECK_RECORD_EXPENSE_PAY_CONFIG)(catchError(function (state, action) {
    return __assign({}, state);
}));
reducer.handle(key.ADD_BATCH_PAYEEINFO)(catchError(function (state, action) {
    return __assign({}, state);
}));
reducer.handle(key.GET_WITH_NOTES_SETTING_ID)(catchError(function (state, action) {
    var id = action.payload.id;
    return __assign(__assign({}, state), { currentWithNotesId: id });
}));
reducer.handle(key.GET_FEETYPE_IMPORT_RULE_LIST)(catchError(function (state, action) {
    return __assign(__assign({}, state), { feeTypeImportRuleList: action.payload.items });
}));
export default reducer;
