import { __awaiter, __generator, __read, __spread } from "tslib";
/**
 *  Created by gym on 2020/3/12 下午2:43.
 */
import { app } from '@ekuaibao/whispered';
import { models } from '@ekuaibao/collection-definition';
import WithnoteGroup from './models/WithnoteGroup';
import WithnoteDetail, { WithnoteDetailForm } from './models/WithnoteDetail';
import WithnoteRelationDetail from './models/WithnoteRelationDetail';
import WithnoteGenerate from './models/WithnoteGenerate';
app.collection.register(__spread(models, [
    WithnoteGroup,
    WithnoteRelationDetail,
    WithnoteDetail,
    WithnoteDetailForm,
    WithnoteGenerate
]));
export default [
    {
        id: '@quick-expends',
        reducer: function () { return require('./record-expends-reducer.ts'); },
        path: '/quick-expends',
        ref: '/',
        onload: function () { return import('./WithnoteView'); },
        onbefore: function (app, next) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, app.dataLoader('@common.userinfo').load()];
                    case 1:
                        _a.sent();
                        next();
                        return [2 /*return*/];
                }
            });
        }); }
    },
    {
        point: '@@layers',
        prefix: '@quick-expends',
        onload: function () { return import('./layers'); }
    }
];
