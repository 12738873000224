/**
 *  Created by gym on 2020/3/12 下午2:49.
 */
export var ID = '@quick-expends';
export default {
    ID: ID,
    GET_WITHNOTES_LIST: ID + "/GET_WITHNOTES_LIST",
    SAVE_WITHNOTES: ID + "/SAVE_WITHNOTES",
    MODIFY_WITHNOTES: ID + "/MODIFY_WITHNOTES",
    DELETE_WITHNOTES: ID + "/DELETE_WITHNOTES",
    DELETE_BATCH_WITHNOTES: ID + "/DELETE_BATCH_WITHNOTES",
    GET_DETAILS_VALUES_BYID: ID + "/GET_DETAILS_VALUES_BYID",
    SET_CAN_ADD_PAY_INFO: ID + "/SET_CAN_ADD_PAY_INFO",
    SHARE_RECORD_EXPENSE: ID + "/SHARE_RECORD_EXPENSE",
    GET_SHARED_STAFFS_LIST: ID + "/GET_SHARED_STAFFS_LIST",
    SET_RECORD_EXPENSE_PAY_CONFIG: ID + "/SET_RECORD_EXPENSE_PAY_CONFIG",
    CHECK_RECORD_EXPENSE_PAY_CONFIG: ID + "/CHECK_RECORD_EXPENSE_PAY_CONFIG",
    ADD_BATCH_PAYEEINFO: ID + "/ADD_BATCH_PAYEEINFO",
    GET_WITH_NOTES_SETTING_ID: ID + "/GET_WITH_NOTES_SETTING_ID",
    GET_FEETYPE_IMPORT_RULE_LIST: ID + "/GET_FEETYPE_IMPORT_RULE_LIST"
};
