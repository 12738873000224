import { __decorate, __extends, __metadata } from "tslib";
/**
 *  Created by pw on 2020/12/15 下午5:19.
 */
import { attribute, Model } from 'mobx-cobweb';
var WithnoteGenerate = /** @class */ (function (_super) {
    __extends(WithnoteGenerate, _super);
    function WithnoteGenerate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WithnoteGenerate.type = 'withnodes.generate';
    WithnoteGenerate.endpoint = '/api/v2/withNotes/generate';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], WithnoteGenerate.prototype, "versionId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], WithnoteGenerate.prototype, "count", void 0);
    return WithnoteGenerate;
}(Model));
export default WithnoteGenerate;
